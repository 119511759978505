<template>
  <div class="overview">
    <div class="overview-basic">
      <div class="company">
        <p class="company-info">{{org.name}}
          <span class="company-info-simple">({{org.simpleName}})</span></p>
        <p class="slog">{{org.slogan}}</p>

        <p class="bill">
          <span>可用余额: </span>
          <span class="bill-number">¥ {{(balance.money / 100) | F1000}}(元)</span>
          <el-button type="primary" size="mini" @click="$router.push({name: 'org.basic.fee'})"
                    v-if="this.$btnCheck('org_finance_bill_recharge')">充值
          </el-button>
        </p>
      </div>
    </div>
    <el-row class="overview-money">
      <el-col :span="12">
        <p class="overview-money-title">预警设置</p>
        <div class="overview-money-content">
          <p><span>可用额度预警：</span>
            <el-switch v-model="balance.alarmSw" size="small" disabled/>
            (<span>预警阀值: ¥{{(balance.alarmMoney / 100) | F1000}}
              <el-button type="text" @click="showAlarmSet = true"
                        v-if="this.$btnCheck('org_basic_alarm')">修改</el-button></span>)
          </p>
          <p><span>预警电话：</span> <span class="mobile">{{balance.alarmPhone}}</span><span>(若未绑定手机，默认通知管理员)</span></p>
        </div>
      </el-col>
      <el-col :span="12" class="padding-middle">
        <p class="overview-money-title">公告</p>
        <ul class="overview-money-content">
          <li>【公告】节省计划邀测</li>
          <li>【公告】2020年10月1日，国庆放假事宜</li>
          <li>【公告】2020年9月30日01:00-08:00云咖v1.0 系统升级发布通知</li>
        </ul>
      </el-col>
    </el-row>

    <div class="overview-chart">
      <p class="title">近12个月收支趋势</p>
      <div style="position: relative;" class="chart-container">
        <div class="year-select">
        </div>
      </div>
      <div class="chart" id="chart"></div>
    </div>

    <el-dialog
      :visible.sync="showAlarmSet"
      width="480px"
      title="预警设置">
      <el-form  label-width="80px">
        <el-form-item label="开启预警">
          <el-switch v-model="alarm.alarmSw"/>
        </el-form-item>

        <el-form-item label="预警电话">
          <el-input size="small" v-model="alarm.alarmPhone"/>
        </el-form-item>

        <el-form-item label="预警阀值">
          <el-input-number size="small" v-model.number="alarm.alarmMoney" type="number"/> <span>  (元)</span>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAlarmSet">提交</el-button>
          <el-button @click="showAlarmSet=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>

</template>

<script>
  import * as echarts from 'echarts';
  import {checkPhone} from "@/utils/check"
  import {mapGetters} from 'vuex'

  export default {
    name: 'org-basic-overview',
    components: {},
    mounted: function () {
      this.$api('account.org.balanceAlarm').then(data => {
        data.alarmSw = data.alarmSw === 1
        this.alarm = {
          alarmSw: data.alarmSw,
          alarmPhone: data.alarmPhone,
          alarmMoney: data.alarmMoney / 100
        }
        this.balance = data
      })

      this.$api('account.bill.balanceMonth').then(data => {
        this._initCharts(data || [])
      })

    },
    computed: {
      ...mapGetters(['org'])
    },
    data: function () {
      return {
        balance: {},
        alarm: {
          alarmSw: false,
          alarmPhone: '',
          alarmMoney: 0
        },
        option: {
          color: [this.$themeColor, '#8ADBE6'],
          legend: {
            type: 'plain',
            right: 300,
          },
          tooltip: {
            show: true,
            trigger: 'item',
            formatter: '{a}:{c0}元'
          },
          grid: {
            left: '5%',
            right: 30,
            bottom: 32,
          },
          xAxis: [
            {
              name: '月',
              type: 'category',
              data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
              axisTick: {
                alignWithLabel: true
              },
              axisLine: {
                lineStyle: {
                  color: '#E2E2E2'
                }
              },
              axisLabel: {
                color: '#333'
              }
            }
          ],
          yAxis: [
            {
              name: '元',
              type: 'value',
              axisLine: {
                lineStyle: {
                  color: '#E2E2E2'
                }
              },
              axisLabel: {
                color: '#333'
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#e2e2e2',
                  type: 'dashed'
                }
              }
            }
          ],
          series: [
            {
              name: '充值',
              type: 'bar',
              barGap: 0,
              barWidth: '15%',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              name: '消费',
              type: 'bar',
              barGap: 0,
              barWidth: '15%',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
          ]
        },
        showAlarmSet: false,
      }
    },
    methods: {
      handleChange() {
      },
      handleAlarmSet() {
        let ok = checkPhone(this.alarm.alarmPhone)
        if (!ok) {
          this.$message.warning('请输入正确的手机号码')
          return
        }
        this.$api('account.org.balanceAlarmSet', {},{
          alarmSw: this.alarm.alarmSw ? 1 : 0,
          alarmPhone: this.alarm.alarmPhone,
          alarmMoney: this.alarm.alarmMoney * 100
        }).then(() => {
          this.$message.success('修改成功')
          this.balance = {
            money: this.balance.money,
            alarmSw: this.alarm.alarmSw,
            alarmPhone: this.alarm.alarmPhone,
            alarmMoney: this.alarm.alarmMoney * 100
          }
          this.showAlarmSet = false
        })
      },
      _initCharts(bills) {
        let date = new Date()
        let year = date.getYear()
        let month = date.getMonth()
        let temp = [
          {
            name: '充值',
            type: 'bar',
            barGap: 0,
            barWidth: '15%',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          },
          {
            name: '消费',
            type: 'bar',
            barGap: 0,
            barWidth: '15%',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ]
        let months = []
        let lastMonth = 1
        if (bills.length) {
          for (let i = 0; i < bills.length; i++) {
            if (bills[i].year === year) {
              months.push(bills[i].month + '')
            } else {
              months.push(bills[i].year + '/' + bills[i].month)
            }
            lastMonth = bills[i].month
            temp[0].data[i] = bills[i].recharge / 100
            temp[1].data[i] = bills[i].consumer / 100
          }
        }

        for (let i = 12 - month; i <= 12; i++) {
          months.push(year - 1 + '/' + i + '')
        }

        for (let i = 1; i <= lastMonth; i++) {
          months.push(i + '')
        }

        this.option.series = temp
        this.option.xAxis.data = months
        let chart = echarts.init(document.getElementById('chart'))
        chart.setOption(this.option)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .overview {
    position: relative;
    &-basic {
      background: url("../../assets/img/org/basic_bg.svg") no-repeat;
      border-radius: $box-radius;
      height: 160px;
      width: 100%;
      background-size: cover;
      padding: $container-padding;

      .company {
        &-info {
          @include font-large();
          font-weight: 500;
          margin-bottom: $small-space;

          &-simple {
            @include font-little();
          }
        }
      }

      .slog {
        //color: $theme-light-9;
        @include font-medium();
        font-weight: 500;
      }

      .bill {
        margin: $middle-space 0;

        &-number {
          margin-left: $small-space;
          display: inline-block;
          @include font-medium();
          color: $theme-color;
          font-weight: 500;
          min-width: 160px;
        }
      }

      .company, .slog {
        color: $color-black;
      }
    }


    &-money {
      margin: 0 $container-padding;
      padding: $middle-space;
      border: 1px solid $content-border-color;
      box-shadow: $box-shadow;
      height: 116px;
      background-color: $color-white;
      top: -$middle-space;


      .padding-middle {
        padding-left: $large-space;
      }

      &-title {
        color: $color-black;
        @include font-medium-s();
        font-weight: 500;
      }


      .el-col:first-child {
        border-right: 1px solid $container-border-color;
      }

      &-content {
        * {
          @include font-little();
        }


        .mobile {
          display: inline-block;
          min-width: 100px;
          font-weight: 500;
        }

        li {
          cursor: pointer;
        }

        li:hover {
          color: $theme-color;
        }

      }
    }


    &-chart {
      padding: 0 $container-padding;
      margin: $middle-space 0;
      height: 45%;

      .title {

        color: $color-black;
        @include font-medium-s();
        font-weight: 500;
      }

      .chart-container {
        position: relative;
        width: 100%;
      }

      .chart {
        width: 100%;
        height: 384px;
      }

    }
  }
</style>
